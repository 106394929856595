import {
  Card,
  BlockStack,
  Text,
  InlineStack,
  Button,
  FormLayout,
  InlineGrid,
} from "@shopify/polaris";
import { useForm } from "react-hook-form";
import { useContext, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Car } from "../../../../types/common.types";
import TextFieldInput from "../../../../componenets/TextFieldInput";
import { queryClient } from "../../../../services/queryClient.service";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../../contexts/alert.context";
import { useUpdateCar } from "../hooks/useUpdateCar";
import { formatMoney } from "../../../../helpers/helpers";

interface CarDetailsProps {
  car: Car;
}

const schema = yup
  .object({
    name: yup.string().required(),
    license_plate: yup.string().required(),
    total_cost: yup.number().required(),
    monthly_payment: yup.number().required(),
    vignette: yup.number().required(),
    yearly_insurance_rate: yup.number().min(0).required(),
    traveled_distance: yup.number().min(0).required(),
  })
  .required();

export default function CarDetails({ car }: CarDetailsProps) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit } = useForm<Car>({
    defaultValues: {
      name: car.name,
      license_plate: car.license_plate,
      chassis_number: car.chassis_number,
      total_cost: car.total_cost,
      monthly_payment: car.monthly_payment,
      vignette: car.vignette,
      yearly_insurance_rate: car.yearly_insurance_rate,
      traveled_distance: car.traveled_distance,
    },
    resolver: yupResolver(schema),
  });

  const updateCar = useUpdateCar();

  const onHandleSubmit = async (carDetails: Car) => {
    setLoading(true);

    try {
      await updateCar({
        carImage: null,
        car: {
          ...car,
          ...carDetails,
        },
      });

      await queryClient.invalidateQueries([queryKeysConstants.cars, car.id]);

      onShowToast("Car Updated", false);

      setEdit(false);
    } catch (e: any) {
      onShowToast("Something went wrong", true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Card>
      <BlockStack gap="500">
        <InlineStack align="space-between">
          <Text as="span" fontWeight="bold">
            {t("details")}
          </Text>
          <Button
            variant="plain"
            onClick={() => setEdit((prevState: boolean) => !prevState)}
            loading={loading}
          >
            {edit ? t("cancel") : t("edit")}
          </Button>
        </InlineStack>
        {!edit && (
          <InlineGrid columns={3} gap="200">
            <p>
              {t("car_name")}
              <br />
              <Text variant="bodyMd" as="span" tone="subdued">
                {car?.name}
              </Text>
            </p>
            <p>
              {t("car_license_plate")}
              <br />
              <Text variant="bodyMd" as="span" tone="subdued">
                {car?.license_plate}
              </Text>
            </p>
            <p>
              {t("chassis_number")}
              <br />
              <Text variant="bodyMd" as="span" tone="subdued">
                {car?.chassis_number}
              </Text>
            </p>
            <p>
              {t("total_cost")}
              <br />
              <Text variant="bodyMd" as="span" tone="subdued">
                {formatMoney(car?.total_cost)}
              </Text>
            </p>
            <p>
              {t("monthly_payment")}
              <br />
              <Text variant="bodyMd" as="span" tone="subdued">
                {formatMoney(car?.monthly_payment)}
              </Text>
            </p>
            <p>
              {t("vignette")}
              <br />
              <Text variant="bodyMd" as="span" tone="subdued">
                {formatMoney(car?.vignette)}
              </Text>
            </p>
            <p>
              {t("yearly_insurance_rate")}
              <br />
              <Text variant="bodyMd" as="span" tone="subdued">
                {formatMoney(car?.yearly_insurance_rate)}
              </Text>
            </p>
            <p>
              {t("traveled_distance")}
              <br />
              <Text variant="bodyMd" as="span" tone="subdued">
                {`${car?.traveled_distance || 0} KM`}
              </Text>
            </p>
            <p>
              {t("monthly_insurance_rate")}
              <br />
              <Text variant="bodyMd" as="span" tone="subdued">
                {formatMoney(car?.monthly_insurance_rate)}
              </Text>
            </p>
            <p>
              {t("min_daily_rate")}
              <br />
              <Text variant="bodyMd" as="span" tone="subdued">
                {formatMoney(car?.min_daily_rate)}
              </Text>
            </p>
          </InlineGrid>
        )}
        {edit && (
          <BlockStack gap="500">
            <FormLayout>
              <FormLayout.Group>
                <TextFieldInput control={control} name="name" label="Name" />
                <TextFieldInput
                  control={control}
                  name="license_plate"
                  label={t("car_license_plate")}
                />
                <TextFieldInput
                  control={control}
                  name="chassis_number"
                  label={t("chassis_number")}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextFieldInput
                  control={control}
                  name="total_cost"
                  label={t("total_cost")}
                  type="number"
                />
                <TextFieldInput
                  control={control}
                  name="monthly_payment"
                  label={t("monthly_payment")}
                  type="number"
                />
                <TextFieldInput
                  control={control}
                  name="vignette"
                  label={t("vignette")}
                  type="number"
                />
              </FormLayout.Group>
              <TextFieldInput
                control={control}
                name="yearly_insurance_rate"
                label={t("yearly_insurance_rate")}
                type="number"
              />
              <TextFieldInput
                control={control}
                name="traveled_distance"
                label={t("traveled_distance")}
                type="number"
              />
            </FormLayout>
            <InlineStack align="end">
              <Button
                variant="primary"
                size="medium"
                fullWidth={false}
                loading={loading}
                onClick={handleSubmit(onHandleSubmit)}
              >
                {t("save")}
              </Button>
            </InlineStack>
          </BlockStack>
        )}
      </BlockStack>
    </Card>
  );
}
