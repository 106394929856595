import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "@shopify/polaris";
import { QueryClientProvider } from "@tanstack/react-query";
// import en from "@shopify/polaris/locales/en.json";
import fr from "@shopify/polaris/locales/fr.json";
import App from "./App";
import "./index.css";
import { queryClient } from "./services/queryClient.service";
import { AlertProvider } from "./contexts/alert.context";
import "./i18n.js";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

function Index() {
  return (
    <BrowserRouter>
      <React.StrictMode>
        <AppProvider i18n={fr} theme="light">
          <QueryClientProvider client={queryClient}>
            <AlertProvider>
              <App />
            </AlertProvider>
          </QueryClientProvider>
        </AppProvider>
      </React.StrictMode>
    </BrowserRouter>
  );
}

root.render(<Index />);
