import { Modal } from "@shopify/polaris";
import React from "react";
import { useTranslation } from "react-i18next";

interface DeleteConfirmationDialogProps {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onDelete: () => void;
}
export default function DeleteConfirmationDialog({
  open,
  loading,
  onClose,
  onDelete,
}: DeleteConfirmationDialogProps) {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("confirmation")}
      primaryAction={{
        content: t("delete"),
        disabled: loading,
        loading,
        onAction: onDelete,
        destructive: true,
      }}
      secondaryActions={[
        {
          content: t("cancel"),
          disabled: loading,
          onAction: () => onClose(),
        },
      ]}
    >
      <Modal.Section>
        {t("are_you_sure_you_want_to_delete_this_item")}
      </Modal.Section>
    </Modal>
  );
}
