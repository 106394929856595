import {
  BlockStack,
  Card,
  Text,
  IndexTable,
  InlineStack,
  Button,
  Box,
  Link,
  useIndexResourceState,
} from "@shopify/polaris";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteIcon } from "@shopify/polaris-icons";
import { useCarCharges } from "./hooks/useCarCharges";
import { CarCharge } from "../../../../../types/common.types";
import { formatDate, formatMoney } from "../../../../../helpers/helpers";
import AddCarChargeModal from "./AddCarChargeModal";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import { useDeleteCarCharges } from "./hooks/useDeleteCarCharges";

export default function CarCharges({ carId }: { carId: string }) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();

  const columns = [
    { label: t("type") },
    { label: t("date") },
    { label: t("amount") },
    { label: t("invoice") },
  ];

  const [modalActive, setModalActive] = useState(false);
  const { minPage, maxPage, isFetching, page, setPage, charges } =
    useCarCharges(carId, 10);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(
    charges.map((charge: CarCharge) => ({ ...charge })),
  );
  const deleteCharges = useDeleteCarCharges(carId);

  const handleDelete = async () => {
    onShowToast("Deleting Charges", false);

    try {
      await deleteCharges(selectedResources);
      await queryClient.invalidateQueries([queryKeysConstants.cars, carId]);

      clearSelection();
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
    }
  };

  const rowMarkup = charges.map((charge: CarCharge, index: number) => (
    <IndexTable.Row
      id={charge.id as string}
      key={charge.id}
      position={index}
      selected={selectedResources.includes(charge.id as string)}
    >
      <IndexTable.Cell>{t(charge.type.toLowerCase())}</IndexTable.Cell>
      <IndexTable.Cell>{formatDate(charge.date, true)}</IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(charge.amount)}</IndexTable.Cell>
      <IndexTable.Cell>
        {charge.invoice_file_public_url ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link url={charge.invoice_file_public_url} target="_blank">
            Invoice
          </Link>
        ) : (
          "-"
        )}
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Card padding="0">
      <BlockStack gap="200">
        <Box
          paddingInlineStart="300"
          paddingInlineEnd="300"
          paddingBlockStart="200"
        >
          <InlineStack align="space-between">
            <Text as="span" fontWeight="bold">
              {t("charges")}
            </Text>
            <Button variant="plain" onClick={() => setModalActive(true)}>
              {t("add")}
            </Button>
          </InlineStack>
        </Box>

        <IndexTable
          resourceName={{
            singular: t("charge"),
            plural: t("charges"),
          }}
          itemCount={charges.length}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          loading={isFetching}
          selectable
          bulkActions={[
            {
              icon: DeleteIcon,
              destructive: true,
              content: t("delete_charges"),
              onAction: handleDelete,
            },
          ]}
          onSelectionChange={handleSelectionChange}
          selectedItemsCount={
            allResourcesSelected ? "All" : selectedResources.length
          }
          pagination={{
            hasPrevious: page > minPage,
            hasNext: maxPage > 1 && page < maxPage,
            onPrevious: () => setPage(page - 1),
            onNext: () => setPage(page + 1),
          }}
        >
          {rowMarkup}
        </IndexTable>
      </BlockStack>
      <AddCarChargeModal
        carId={carId}
        active={modalActive}
        setActive={setModalActive}
      />
    </Card>
  );
}
