import {
  Card,
  BlockStack,
  Text,
  InlineStack,
  Button,
  FormLayout,
  InlineGrid,
  TextField,
} from "@shopify/polaris";
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useTranslation } from "react-i18next";
import { Rent } from "../../../../types/common.types";
import { addDays, formatDate, formatMoney } from "../../../../helpers/helpers";
import TextFieldInput from "../../../../componenets/TextFieldInput";
import DateTimePickerInput from "../../../../componenets/DateTimePickerInput";
import AlertContext, {
  AlertContextType,
} from "../../../../contexts/alert.context";
import { useUpdateRent } from "../hooks/useUpdateRent";
import { queryClient } from "../../../../services/queryClient.service";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import CheckboxInput from "../../../../componenets/CheckboxInput";
import { GridField } from "../../../../componenets/GridField";

interface Props {
  rent: Rent;
}

const schema = yup
  .object({
    from_date: yup.date().required(),
    to_date: yup.date().required(),
    is_flat_fee_rent: yup.boolean(),
    price_per_day: yup.number().min(10).required(),
    number_of_days: yup.number().min(1).required(),
    total: yup.number().min(10).required(),
  })
  .required();

export default function RentDetails({ rent }: Props) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, setValue, watch, getValues } = useForm<Rent>({
    defaultValues: {
      ...rent,
      to_date: new Date(rent.to_date as Date),
      from_date: new Date(rent.from_date as Date),
    },
    resolver: yupResolver(schema),
  });

  const watchIsFlatFeeRent = watch("is_flat_fee_rent");
  const watchPricePerDay = watch("price_per_day");
  const watchFromDate = watch("from_date");
  const watchToDate = watch("to_date");
  const watchNumberOfDays = watch("number_of_days");
  const watchTotal = watch("total");

  const updateRent = useUpdateRent();

  const onHandleSubmit = async (_rent: Rent) => {
    setLoading(true);

    try {
      await updateRent({
        contractFile: null,
        rent: {
          id: rent.id,
          car_id: rent.car_id,
          is_flat_fee_rent: _rent.is_flat_fee_rent,
          price_per_day: _rent.price_per_day,
          number_of_days: _rent.number_of_days,
          total: _rent.total,
          delivery_fee: _rent.delivery_fee,
          from_date: _rent.from_date,
          to_date: _rent.to_date,
        },
      });

      await queryClient.invalidateQueries([queryKeysConstants.rents, rent.id]);

      onShowToast("Car Details Updated", false);
      setEdit(false);
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);

      console.error(e.response.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    setValue("from_date", rent.from_date);
    setValue("to_date", rent.to_date);
  }, [rent]);

  useEffect(() => {
    if (getValues("is_flat_fee_rent")) {
      return;
    }

    const pricePerDay: number = getValues("price_per_day") || 0;
    const numberOfDays: number = getValues("number_of_days") || 0;

    setValue("total", pricePerDay * numberOfDays);
  }, [watchPricePerDay, watchNumberOfDays]);

  useEffect(() => {
    const numberOfDays = getValues("number_of_days");
    const fromDate = getValues("from_date");

    setValue("to_date", addDays(fromDate as Date, numberOfDays as number));
  }, [watchNumberOfDays, watchFromDate]);

  useEffect(() => {
    const total: number = getValues("total") || 0;

    if (!getValues("is_flat_fee_rent")) {
      return;
    }

    const numberOfDays: number = getValues("number_of_days") || 1;

    setValue("price_per_day", total / numberOfDays);
  }, [watchTotal]);

  return (
    <Card>
      <BlockStack gap="500">
        <InlineStack align="space-between">
          <Text as="span" fontWeight="bold">
            {t("details")}
          </Text>
          <Button
            variant="plain"
            onClick={() => setEdit((prevState: boolean) => !prevState)}
            loading={loading}
          >
            {edit ? t("cancel") : t("edit")}
          </Button>
        </InlineStack>
        {!edit && (
          <BlockStack gap="500">
            <p>
              {t("is_flat_fee_rent")}
              <br />
              <Text variant="bodyMd" as="span" tone="subdued">
                {rent.is_flat_fee_rent ? t("yes") : t("no")}
              </Text>
            </p>
            <InlineGrid gap="200" columns={2}>
              {!rent.is_flat_fee_rent && (
                <GridField name={t("price_per_day")}>
                  <Text variant="bodyMd" as="span" tone="subdued">
                    {formatMoney(rent.price_per_day as number)}
                  </Text>
                </GridField>
              )}
              <GridField name={t("number_of_days")}>
                <Text variant="bodyMd" as="span" tone="subdued">
                  {rent.number_of_days}
                </Text>
              </GridField>
              <GridField name={t("total")}>
                <Text variant="bodyMd" as="span" tone="subdued">
                  {formatMoney(rent.total as number)}
                </Text>
              </GridField>
              <GridField name={t("delivery_fee")}>
                <Text variant="bodyMd" as="span" tone="subdued">
                  {formatMoney(rent.delivery_fee as number)}
                </Text>
              </GridField>
              <GridField name={t("from_date")}>
                <Text as="span" variant="bodyMd" tone="subdued">
                  {formatDate(rent.from_date as Date)}
                </Text>
              </GridField>
              <GridField name={t("to_date")}>
                <Text as="span" variant="bodyMd" tone="subdued">
                  {formatDate(rent.to_date as Date, false, true)}
                </Text>
              </GridField>
            </InlineGrid>
          </BlockStack>
        )}
        {edit && (
          <BlockStack gap="500">
            <FormLayout>
              <CheckboxInput
                control={control}
                name="is_flat_fee_rent"
                label={t("is_flat_fee_rent")}
              />
              <FormLayout.Group>
                {!watchIsFlatFeeRent && (
                  <TextFieldInput
                    control={control}
                    name="price_per_day"
                    label={t("price_per_day")}
                  />
                )}
                <TextFieldInput
                  control={control}
                  name="number_of_days"
                  label={t("number_of_days")}
                />
                <TextFieldInput
                  control={control}
                  name="total"
                  label={t("total")}
                  disabled={!watchIsFlatFeeRent}
                />
              </FormLayout.Group>
              <TextFieldInput
                control={control}
                name="delivery_fee"
                label={t("delivery_fee")}
                type="number"
              />
              <DateTimePickerInput
                control={control}
                name="from_date"
                initialDate={rent.from_date}
                dateLabel={t("from_date")}
                timeLabel={t("from_time")}
              />
              <TextField
                value={formatDate(watchToDate, false, true)}
                autoComplete=""
                label={t("to_date")}
                disabled
              />
            </FormLayout>
            <InlineStack align="end">
              <Button
                variant="primary"
                size="medium"
                fullWidth={false}
                loading={loading}
                onClick={handleSubmit(onHandleSubmit)}
              >
                {t("save")}
              </Button>
            </InlineStack>
          </BlockStack>
        )}
      </BlockStack>
    </Card>
  );
}
