import {
  BlockStack,
  Card,
  FormLayout,
  Layout,
  Page,
  Text,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useAuthState } from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { auth } from "../../../services/firebase.service";
import TextFieldInput from "../../../componenets/TextFieldInput";
import { useUpdateUser } from "./hooks/useUpdateUser";

const schema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

export default function ProfilePage() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [user] = useAuthState(auth);
  const { control, handleSubmit } = useForm<any>({
    defaultValues: {
      name: user?.displayName || "",
      email: user?.email || "",
    },
    resolver: yupResolver(schema),
  });

  const updateUser = useUpdateUser();

  const onHandleSubmit = async (profile: any) => {
    setLoading(true);

    await updateUser(profile);

    setLoading(false);
  };

  return (
    <Page
      title={t("profile")}
      primaryAction={{
        content: t("save"),
        onAction: handleSubmit(onHandleSubmit),
        loading,
      }}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <BlockStack gap="200">
              <Text as="span" fontWeight="bold">
                {t("details")}
              </Text>
              <FormLayout>
                <TextFieldInput
                  control={control}
                  name="name"
                  label={t("name")}
                  disabled={loading}
                />
                <TextFieldInput
                  control={control}
                  name="email"
                  label={t("email")}
                  disabled={loading}
                />
              </FormLayout>
            </BlockStack>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
