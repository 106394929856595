import {
  FormLayout,
  Layout,
  Page,
  Text,
  Card,
  BlockStack,
} from "@shopify/polaris";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useTranslation } from "react-i18next";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Invoice, Customer, Rent } from "../../../types/common.types";
import { useCustomers } from "../Customers/hooks/useCustomers";
import DatePickerInput from "../../../componenets/DatePickerInput";
import AutoCompleteInput from "../../../componenets/AutoCompleteInput";
import TextFieldInput from "../../../componenets/TextFieldInput";
import SelectInput from "../../../componenets/SelectInput";
import { paymentTypes } from "../../../constants/other.constants";
import InvoiceRents from "./components/InvoiceRents/InvoiceRents";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { useAddInvoice } from "./hooks/useAddInvoice";

const schema = yup
  .object({
    customer_id: yup.string().required(),
    vat: yup.number().required(),
    payment_type: yup.string().required(),
  })
  .required();

export default function NewInvoicePage() {
  const navigate = useNavigate();
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [invoiceRents, setInvoiceRents] = useState<Rent[]>([]);

  const { isLoading: isCustomersLoading, customers } = useCustomers(100);
  const addInvoice = useAddInvoice();

  const { control, handleSubmit, watch } = useForm<Invoice>({
    defaultValues: {
      invoice_date: new Date(),
      vat: 20,
      payment_type: paymentTypes[0],
    },
    resolver: yupResolver(schema),
  });

  const watchCustomerId = watch("customer_id");
  const watchVat = watch("vat");

  const onHandleSubmit = async (invoice: Invoice) => {
    if (!invoiceRents.length) {
      onShowToast(t("please_select_at_least_one_rent"), true);

      return;
    }

    setLoading(true);

    await addInvoice({
      ...invoice,
      rents: invoiceRents.map((rent: Rent) => rent.id),
    } as Invoice);

    try {
      await queryClient.invalidateQueries([queryKeysConstants.invoices]);

      onShowToast(t("invoice_saved"), false);

      navigate(`/admin/invoices`);
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);

      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Page
      title={t("new_invoice")}
      fullWidth
      backAction={{
        url: "/admin/invoices",
      }}
      primaryAction={{
        content: t("save"),
        disabled: loading || isCustomersLoading,
        loading: loading || isCustomersLoading,
        onAction: handleSubmit(onHandleSubmit),
      }}
    >
      <Layout>
        <Layout.Section variant="oneHalf">
          <Card>
            <BlockStack gap="200">
              <Text variant="headingMd" as="h2">
                {t("invoice_details")}
              </Text>
              <FormLayout>
                <DatePickerInput
                  control={control}
                  name="invoice_date"
                  label={t("invoice_date")}
                />
                <TextFieldInput control={control} name="vat" label={t("vat")} />
                <SelectInput
                  control={control}
                  name="payment_type"
                  label={t("payment_type")}
                  options={paymentTypes.map((paymentType) => ({
                    label: t(paymentType.toLowerCase()),
                    value: paymentType,
                  }))}
                />
              </FormLayout>
            </BlockStack>
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <Card>
            <BlockStack gap="200">
              <Text variant="headingMd" as="h2">
                {t("customer_details")}
              </Text>
              <FormLayout>
                <AutoCompleteInput
                  control={control}
                  name="customer_id"
                  label={t("customer")}
                  deselectedOptions={customers.map((customer: Customer) => ({
                    label: customer.name,
                    value: customer.id as string,
                  }))}
                />
              </FormLayout>
            </BlockStack>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <InvoiceRents
            invoiceRents={invoiceRents}
            setInvoiceRents={setInvoiceRents}
            customerId={watchCustomerId}
            vat={watchVat}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
