import { useNavigate, useParams } from "react-router-dom";
import {
  Page,
  SkeletonPage,
  Layout,
  SkeletonBodyText,
  TextContainer,
  SkeletonDisplayText,
  Card,
  InlineGrid,
  Text,
  BlockStack,
} from "@shopify/polaris";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCar } from "./hooks/useCar";
import CarDetails from "./components/CarDetails";
import { Car } from "../../../types/common.types";
import CarImage from "./components/CarImage";
import MonthlyPayments from "./components/MonthlyPayments/MonthlyPayments";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import { useDeleteCars } from "./hooks/useDeleteCars";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import CarDocuments from "./components/CarDocuments/CarDocuments";
import CarCharges from "./components/CarCharges/CarCharges";
import { formatDistance, formatMoney } from "../../../helpers/helpers";
import CarOilChanges from "./components/CarOilChanges/CarOilChanges";

export default function CarPage() {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();

  const { carId } = useParams();
  const navigate = useNavigate();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const { car, isLoading, isError } = useCar(carId as string);

  const deleteCars = useDeleteCars();

  const handleDeleteCar = async () => {
    setDeleteLoading(false);

    try {
      await deleteCars([carId as string]);

      onShowToast("Car Deleted", false);

      navigate("/admin/cars");

      await queryClient.invalidateQueries([queryKeysConstants.cars]);
    } catch (e: any) {
      onShowToast("Something went wrong", true);

      console.error(e.response.data);
    }
  };

  if (isError) {
    navigate("/admin/cars");
  }

  if (isLoading)
    return (
      <SkeletonPage fullWidth>
        <Layout>
          <Layout.Section>
            <Card>
              <SkeletonBodyText />
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    );

  return (
    <Page
      fullWidth
      title={`${car?.name} (${car?.license_plate})`}
      backAction={{
        onAction: () => navigate("/admin/cars"),
      }}
      primaryAction={{
        content: t("delete"),
        destructive: true,
        onAction: handleDeleteCar,
        loading: deleteLoading,
      }}
    >
      <Layout>
        <Layout.Section variant="fullWidth">
          <InlineGrid columns={2} gap="400">
            <Card>
              <BlockStack>
                <Text variant="bodyMd" as="span">
                  {t("average_monthly_cost")}
                </Text>
                <Text variant="heading2xl" fontWeight="semibold" as="h3">
                  {formatMoney(car?.average_monthly_cost || 0)}
                </Text>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack>
                <Text variant="bodyMd" as="span">
                  {t("next_oil_change_at")}
                </Text>
                <Text variant="heading2xl" fontWeight="semibold" as="h3">
                  {formatDistance(car?.next_oil_change_at || 0)}
                </Text>
              </BlockStack>
            </Card>
          </InlineGrid>
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <CarDetails car={car as Car} />
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <CarImage car={car as Car} />
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <CarDocuments carId={(car as Car).id as string} />
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <CarOilChanges carId={(car as Car).id as string} />
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <MonthlyPayments car={car as Car} />
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <CarCharges carId={(car as Car).id as string} />
        </Layout.Section>
      </Layout>
      <div style={{ marginTop: "20px" }} />
    </Page>
  );
}
