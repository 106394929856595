import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";

async function getInfractionReport(infractionId: string): Promise<any> {
  return axiosInstance.get(`/infractions/${infractionId}/infraction_report`, {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useDownloadInfractionReport(
  rentId: string,
): UseMutateAsyncFunction<any, unknown, void, unknown> {
  const { mutateAsync } = useMutation(() => getInfractionReport(rentId));

  return mutateAsync;
}
