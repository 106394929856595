import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { CashRegisterFunds } from "../../../../types/common.types";

async function addCashRegisterFund(
  fund: CashRegisterFunds,
): Promise<CashRegisterFunds> {
  return axiosInstance.post(`/cash_register/funds/`, fund, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useAddCashRegisterFund(): UseMutateAsyncFunction<
  CashRegisterFunds,
  unknown,
  CashRegisterFunds,
  unknown
> {
  const { mutateAsync } = useMutation((fund: CashRegisterFunds) =>
    addCashRegisterFund(fund),
  );

  return mutateAsync;
}
