import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { auth as firebaseAuth } from "./firebase.service";
import { User } from "../types/common.types";

export async function logInWithEmailAndPassword(user: User): Promise<any> {
  return signInWithEmailAndPassword(firebaseAuth, user.email, user.password);
}

export async function sendForgotPasswordEmail(email: string) {
  return sendPasswordResetEmail(firebaseAuth, email);
}

export async function logOut() {
  return signOut(firebaseAuth);
}

export async function getAccessToken(): Promise<string | undefined> {
  return firebaseAuth.currentUser?.getIdToken();
}
