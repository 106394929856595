import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function deleteOilChanges(carId: string, ids: string[]): Promise<void> {
  await axiosInstance.delete(
    `/cars/${carId}/oil_changes?ids=${ids.join(",")}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    },
  );
}

export function useDeleteCarOilChanges(
  carId: string,
): UseMutateAsyncFunction<void, unknown, string[], unknown> {
  const { mutateAsync } = useMutation((ids: string[]) =>
    deleteOilChanges(carId, ids),
  );

  return mutateAsync;
}
