import { Card, IndexTable, Link, Page, Text } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { useNavigate } from "react-router-dom";
import React from "react";
import { Invoice } from "../../../types/common.types";
import { useInvoices } from "./hooks/useInvoices";
import { formatDate, formatMoney } from "../../../helpers/helpers";

export default function InvoicesPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { minPage, maxPage, isFetching, page, setPage, invoices } =
    useInvoices(10);

  const columns = [
    { label: t("number") },
    { label: t("customer_name") },
    { label: t("vat") },
    { label: t("date") },
    { label: t("total_ht") },
    { label: t("total_ttc") },
  ];

  const rowMarkup = invoices.map((invoice: Invoice, index) => (
    <IndexTable.Row id={invoice.id as string} key={invoice.id} position={index}>
      <IndexTable.Cell>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link onClick={() => navigate(`/admin/invoices/${invoice.id}`)}>
          <Text fontWeight="bold" as="span">
            {invoice.formatted_id}
          </Text>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          onClick={() => navigate(`/admin/customers/${invoice.customer_id}`)}
        >
          <Text fontWeight="bold" as="span">
            {invoice.customer?.name}
          </Text>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>{`${invoice.vat}%`}</IndexTable.Cell>
      <IndexTable.Cell>
        {formatDate(invoice.invoice_date, true)}
      </IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(invoice.total_ht || 0)}</IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(invoice.total_ati || 0)}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page
      fullWidth
      title={t("invoices")}
      primaryAction={{
        content: t("add"),
        onAction: () => {
          navigate("/admin/invoices/new");
        },
      }}
    >
      <Card padding="0">
        <IndexTable
          resourceName={{
            singular: t("invoice"),
            plural: t("invoices"),
          }}
          selectable={false}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          itemCount={invoices.length}
          loading={isFetching}
          pagination={{
            hasPrevious: page !== minPage,
            hasNext: page !== maxPage && page < maxPage,
            onPrevious: () => setPage(page - 1),
            onNext: () => setPage(page + 1),
          }}
        >
          {rowMarkup}
        </IndexTable>
      </Card>
    </Page>
  );
}
