import { useQuery } from "@tanstack/react-query";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Rent } from "../../../../types/common.types";

async function getRents(invoiceId: string): Promise<Rent[]> {
  const { data } = await axiosInstance.get(`/invoices/${invoiceId}/rents`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return data;
}

interface useProps {
  isLoading: boolean;
  rents: Rent[];
}

export function useInvoiceRents(invoiceId: string): useProps {
  const { data, isLoading } = useQuery(
    [queryKeysConstants.invoices, invoiceId, "rents"],
    () => getRents(invoiceId),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading,
    rents: data || [],
  };
}
