import {
  Card,
  IndexFilters,
  IndexTable,
  Page,
  TabProps,
  Text,
  useSetIndexFiltersMode,
  Link,
} from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "@uidotdev/usehooks";
import { useTranslation } from "react-i18next";
import { useCars } from "./hooks/useCars";
import { Car } from "../../../types/common.types";
import NewCarModal from "./NewCarModal";
import { formatDistance, formatMoney } from "../../../helpers/helpers";

export default function CarsPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mode, setMode } = useSetIndexFiltersMode();

  const [carModalActive, setCarModalActive] = useState(false);
  const [selected, setSelected] = useState(0);
  const [status, setStatus] = useState("active");
  const [queryValue, setQueryValue] = useState("");
  const [query] = useDebounce([queryValue], 1000);

  const { minPage, maxPage, isFetching, page, setPage, cars } = useCars(
    10,
    status,
    query,
  );

  const tabs: TabProps[] = [
    {
      id: "all",
      content: t("all"),
      isLocked: true,
    },
  ];
  const columns = [
    t("car_name"),
    t("car_license_plate"),
    t("traveled_distance"),
    t("next_oil_change_at"),
    t("documents_count"),
    t("average_monthly_cost"),
  ];

  const handleFiltersQueryChange = useCallback(
    (value: string) => setQueryValue(value),
    [],
  );

  const handleTabChange = useCallback((value: number) => {
    setSelected(value);
    setStatus(tabs[value].id);
  }, []);

  const rowMarkup = cars.map((car: Car, index) => (
    <IndexTable.Row id={car.id as string} key={car.id} position={index}>
      <IndexTable.Cell>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link dataPrimaryLink onClick={() => navigate(`/admin/cars/${car.id}`)}>
          <Text fontWeight="bold" as="span">
            {car.name}
          </Text>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>{car.license_plate}</IndexTable.Cell>
      <IndexTable.Cell>{formatDistance(car.traveled_distance)}</IndexTable.Cell>
      <IndexTable.Cell>
        {formatDistance(car.next_oil_change_at)}
      </IndexTable.Cell>
      <IndexTable.Cell>{car.documents_count}</IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(car.average_monthly_cost)}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page
      fullWidth
      title={t("cars")}
      primaryAction={{
        content: t("add"),
        onAction: () => {
          setCarModalActive(true);
        },
      }}
    >
      <Card padding="0">
        <IndexFilters
          mode={mode}
          setMode={setMode}
          filters={[]}
          queryValue={queryValue}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={() => setQueryValue("")}
          onClearAll={() => {}}
          tabs={tabs}
          selected={selected}
          onSelect={handleTabChange}
          cancelAction={{
            onAction: () => setQueryValue(""),
          }}
          canCreateNewView={false}
        />
        <IndexTable
          resourceName={{
            singular: t("car"),
            plural: t("cars"),
          }}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          itemCount={cars.length}
          loading={isFetching}
          selectable={false}
          pagination={{
            hasPrevious: page > minPage,
            hasNext: maxPage > 1 && page < maxPage,
            onPrevious: () => setPage(page - 1),
            onNext: () => setPage(page + 1),
          }}
        >
          {rowMarkup}
        </IndexTable>
      </Card>
      <NewCarModal active={carModalActive} setActive={setCarModalActive} />
    </Page>
  );
}
