import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { CarDocument } from "../../../../../../types/common.types";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function addCarDocument(
  carId: string,
  carDocument: CarDocument,
  documentFile: File,
): Promise<CarDocument> {
  const form = new FormData();

  form.append("file", documentFile);

  return axiosInstance.post(`/cars/${carId}/documents`, form, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    params: carDocument,
  });
}

async function updateCarDocument(
  carId: string,
  carDocument: CarDocument,
  documentFile: File | null,
): Promise<CarDocument> {
  const form = new FormData();

  if (documentFile) {
    form.append("file", documentFile);
  } else {
    form.append("no-file", "true");
  }

  return axiosInstance.put(`/cars/${carId}/documents/${carDocument.id}`, form, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    params: carDocument,
  });
}

interface addCarDocumentProps {
  carDocument: CarDocument;
  documentFile: File | null;
}

export function useAddEditCarDocument(
  carId: string,
  update: boolean,
): UseMutateAsyncFunction<CarDocument, unknown, addCarDocumentProps, unknown> {
  const { mutateAsync } = useMutation(
    ({ carDocument, documentFile }: addCarDocumentProps) =>
      update
        ? updateCarDocument(carId, carDocument, documentFile)
        : addCarDocument(carId, carDocument, documentFile as File),
  );

  return mutateAsync;
}
