import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function deleteDocument(
  carId: string,
  documentId: string,
): Promise<void> {
  return axiosInstance.delete(`/cars/${carId}/documents/${documentId}`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useDeleteDocument(
  carId: string,
): UseMutateAsyncFunction<void, unknown, string, unknown> {
  const { mutateAsync } = useMutation((documentId: string) =>
    deleteDocument(carId, documentId),
  );

  return mutateAsync;
}
