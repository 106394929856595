import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "../../../../../../services/axios.service";
import { Rent } from "../../../../../../types/common.types";
import { getAccessToken } from "../../../../../../services/auth.service";
import queryKeysConstants from "../../../../../../constants/queryKeys.constants";

async function getUserRents(customerId: string | null): Promise<Rent[]> {
  if (!customerId) {
    return [];
  }

  const { data } = await axiosInstance.get(`/customers/${customerId}/rents`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });

  return data;
}

interface useProps {
  isFetching: boolean;
  isLoading: boolean;
  rents: Rent[];
}

export function useUserRents(customerId: string | null): useProps {
  const { data, isLoading, isFetching } = useQuery(
    [queryKeysConstants.customers, customerId, "rents"],
    () => getUserRents(customerId),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isFetching,
    isLoading,
    rents: data || [],
  };
}
