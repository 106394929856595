import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";

async function getInvoicePdf(invoiceId: string): Promise<any> {
  return axiosInstance.get(`/invoices/${invoiceId}/pdf`, {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function usePrintInvoice(
  invoiceId: string,
): UseMutateAsyncFunction<any, unknown, void, unknown> {
  const { mutateAsync } = useMutation(() => getInvoicePdf(invoiceId));

  return mutateAsync;
}
