import {
  Card,
  IndexTable,
  InlineStack,
  Page,
  Pagination,
} from "@shopify/polaris";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CashRegisterFunds } from "../../../types/common.types";
import { useCashRegisterFunds } from "./hooks/useCashRegisterFunds";
import { formatDate, formatMoney } from "../../../helpers/helpers";
import NewCashRegisterFundModal from "./NewCashRegisterFundModal";

export default function CashRegister() {
  const { t } = useTranslation();

  const [modalActive, setModalActive] = useState(false);

  const { minPage, maxPage, isFetching, page, setPage, funds } =
    useCashRegisterFunds(10);

  const columns = [
    { label: t("label") },
    { label: t("amount") },
    { label: t("date") },
  ];

  const rowMarkup = funds.map((fund: CashRegisterFunds, index) => (
    <IndexTable.Row id={fund.id as string} key={fund.id} position={index}>
      <IndexTable.Cell>{fund.label}</IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(fund.amount)}</IndexTable.Cell>
      <IndexTable.Cell>{formatDate(fund.date, true, true)}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page
      fullWidth
      title={t("cash_register")}
      primaryAction={{
        content: t("add"),
        onAction: () => setModalActive(true),
      }}
    >
      <Card padding="0">
        <IndexTable
          resourceName={{
            singular: t("fund"),
            plural: t("funds"),
          }}
          itemCount={funds.length}
          loading={isFetching}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          selectable={false}
        >
          {rowMarkup}
        </IndexTable>
        <div
          style={{
            borderTop: ".0625rem solid var(--p-border-subdued)",
            margin: "auto",
            padding: "1.5rem 1rem",
          }}
        >
          <InlineStack align="center">
            <Pagination
              hasPrevious={page > minPage}
              hasNext={page < maxPage}
              onPrevious={() => setPage(page - 1)}
              onNext={() => setPage(page + 1)}
            />
          </InlineStack>
        </div>
      </Card>
      <NewCashRegisterFundModal
        active={modalActive}
        setActive={setModalActive}
      />
    </Page>
  );
}
