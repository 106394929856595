import {
  BlockStack,
  Card,
  InlineGrid,
  InlineStack,
  Link,
  Text,
} from "@shopify/polaris";
import React from "react";
import { useTranslation } from "react-i18next";
import { Infraction } from "../../../../types/common.types";
import { GridField } from "../../../../componenets/GridField";
import { formatDate } from "../../../../helpers/helpers";

export default function InfractionInternalDetails({
  infraction,
}: {
  infraction: Infraction;
}) {
  const { t } = useTranslation();

  return (
    <Card>
      <BlockStack gap="500">
        <InlineStack align="space-between">
          <Text as="span" fontWeight="bold">
            {t("internal_details")}
          </Text>
        </InlineStack>
        <InlineGrid columns={3} gap="200">
          <GridField name={t("car_name")}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link url={`/admin/cars/${infraction.car.id}`} target="_blank">
              {`${infraction.car.name} (${infraction.car.license_plate})`}
            </Link>
          </GridField>
          <GridField name={t("customer")}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              url={`/admin/customers/${infraction.customer.id}`}
              target="_blank"
            >
              {`${infraction.customer.name} (${infraction.customer.driver_license_number})`}
            </Link>
          </GridField>
          <GridField name={t("rent")}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link url={`/admin/rents/${infraction.rent.id}`} target="_blank">
              {`${formatDate(infraction.rent.from_date)} - ${formatDate(infraction.rent.to_date)}`}
            </Link>
          </GridField>
        </InlineGrid>
      </BlockStack>
    </Card>
  );
}
