import { BlockStack, Button, Card, InlineStack, Text } from "@shopify/polaris";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AlertContext, {
  AlertContextType,
} from "../../../../contexts/alert.context";
import { queryClient } from "../../../../services/queryClient.service";
import queryKeysConstants from "../../../../constants/queryKeys.constants";
import ImageFileUpload from "../../../../componenets/ImageFileUpload";
import { Rent } from "../../../../types/common.types";
import { useUpdateRent } from "../hooks/useUpdateRent";

interface Props {
  rent: Rent;
}

export default function ContractDetails({ rent }: Props) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [contractFile, setContractFile] = useState<File | null>(null);
  const updateRent = useUpdateRent();

  const onHandleSubmit = async () => {
    setLoading(true);

    try {
      await updateRent({
        contractFile,
        rent: {
          ...rent,
        },
      });
      await queryClient.invalidateQueries([queryKeysConstants.rents, rent.id]);

      onShowToast("Contract Updated", false);

      setContractFile(null);

      setEdit(false);
    } catch (e: any) {
      onShowToast("Something went wrong", true);

      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Card>
      <BlockStack gap="500">
        <InlineStack align="space-between">
          <Text as="span" fontWeight="bold">
            {t("contract")}
          </Text>
          <Button
            variant="plain"
            onClick={() =>
              setEdit((prevState: boolean) => {
                if (!prevState) {
                  setContractFile(null);
                }

                return !prevState;
              })
            }
            loading={loading}
          >
            {edit ? t("cancel") : t("edit")}
          </Button>
        </InlineStack>
        {!edit && (
          <BlockStack gap="200">
            <p>
              <div className="afaLZ h200 p20">
                <div className="qT8wK">
                  <img
                    className="llVfq"
                    src={rent?.contract_public_url as string}
                    alt="Contract"
                  />
                </div>
              </div>
            </p>
          </BlockStack>
        )}
        {edit && (
          <BlockStack gap="500">
            <ImageFileUpload
              label={t("upload")}
              file={contractFile}
              setFile={setContractFile}
            />
            <InlineStack align="end">
              <Button
                variant="primary"
                size="medium"
                fullWidth={false}
                loading={loading}
                onClick={onHandleSubmit}
              >
                {t("save")}
              </Button>
            </InlineStack>
          </BlockStack>
        )}
      </BlockStack>
    </Card>
  );
}
