import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Profile } from "../../../../types/common.types";

async function updateUser(profile: Profile): Promise<Profile> {
  return axiosInstance.put(`/users/`, profile, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useUpdateUser(): UseMutateAsyncFunction<
  Profile,
  unknown,
  Profile,
  unknown
> {
  const { mutateAsync } = useMutation((profile: Profile) =>
    updateUser(profile),
  );

  return mutateAsync;
}
