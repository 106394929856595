import {
  BlockStack,
  Card,
  InlineGrid,
  InlineStack,
  Link,
  Text,
} from "@shopify/polaris";
import React from "react";
import { useTranslation } from "react-i18next";
import { Infraction } from "../../../../types/common.types";
import { formatDate } from "../../../../helpers/helpers";
import { GridField } from "../../../../componenets/GridField";

export default function InfractionDetails({
  infraction,
}: {
  infraction: Infraction;
}) {
  const { t } = useTranslation();

  return (
    <Card>
      <BlockStack gap="500">
        <InlineStack align="space-between">
          <Text as="span" fontWeight="bold">
            {t("details")}
          </Text>
        </InlineStack>
        <InlineGrid columns={2} gap="200">
          <GridField name={t("car_license_plate")}>
            {infraction.license_plate}
          </GridField>
          <GridField name={t("date")}>{formatDate(infraction.date)}</GridField>
          <GridField name={t("place")}>{infraction.place}</GridField>
          <GridField name={t("reason")}>{infraction.label}</GridField>
          <GridField name={t("notice")}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link url={infraction.pdf_link as string} target="_blank">
              {t("open")}
            </Link>
          </GridField>
          <GridField name={t("status")}>
            {t(infraction.status.toLowerCase())}
          </GridField>
        </InlineGrid>
      </BlockStack>
    </Card>
  );
}
