import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { CarOilChange } from "../../../../../../types/common.types";
import { axiosInstance } from "../../../../../../services/axios.service";
import { getAccessToken } from "../../../../../../services/auth.service";

async function addCarOilChange(
  carId: string,
  oilChange: CarOilChange,
  invoice: File | null,
): Promise<CarOilChange> {
  const form = new FormData();

  if (invoice) {
    form.append("invoice", invoice);
  } else {
    form.append("no-file", "true");
  }

  return axiosInstance.post(`/cars/${carId}/oil_changes`, form, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    params: oilChange,
  });
}

interface Props {
  oilChange: CarOilChange;
  invoice: File | null;
}

export function useAddCarOilChange(
  carId: string,
): UseMutateAsyncFunction<CarOilChange, unknown, Props, unknown> {
  const { mutateAsync } = useMutation(({ oilChange, invoice }: Props) =>
    addCarOilChange(carId, oilChange, invoice),
  );

  return mutateAsync;
}
