import {
  BlockStack,
  Card,
  InlineGrid,
  InlineStack,
  Text,
} from "@shopify/polaris";
import React from "react";
import { useTranslation } from "react-i18next";
import { Declaration } from "../../../../types/common.types";
import { formatDate } from "../../../../helpers/helpers";
import { GridField } from "../../../../componenets/GridField";

export default function DeclarationDetails({
  declaration,
}: {
  declaration: Declaration;
}) {
  const { t } = useTranslation();

  return (
    <Card>
      <BlockStack gap="500">
        <InlineStack align="space-between">
          <Text as="span" fontWeight="bold">
            {t("declaration_details")}
          </Text>
        </InlineStack>
        <InlineGrid columns={2} gap="200">
          <GridField name={t("status")}>
            {t(declaration.status.toLowerCase())}
          </GridField>
          <GridField name={t("date")}>
            {formatDate(declaration.declared_at)}
          </GridField>
        </InlineGrid>
      </BlockStack>
    </Card>
  );
}
