import React, { useState } from "react";
import { TopBar } from "@shopify/polaris";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation } from "react-i18next";
import { ExitIcon, PersonIcon } from "@shopify/polaris-icons";
import { logOut } from "../services/auth.service";
import { queryClient } from "../services/queryClient.service";
import queryKeysConstants from "../constants/queryKeys.constants";
import { auth } from "../services/firebase.service";

interface Props {
  onNavigationToggle?(): void;
}

export default function Header({ onNavigationToggle }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [user, , error] = useAuthState(auth);

  const onLogOut = async () => {
    await logOut();
    await queryClient.invalidateQueries([queryKeysConstants.user]);

    navigate("/auth/login", {
      replace: true,
    });
  };

  const userMenuMarkup =
    user && !error ? (
      <TopBar.UserMenu
        actions={[
          {
            items: [
              {
                icon: PersonIcon,
                content: t("profile"),
                onAction: () => navigate("/admin/profile"),
              },
            ],
          },
          {
            items: [
              {
                icon: ExitIcon,
                content: t("log_out"),
                onAction: onLogOut,
              },
            ],
          },
        ]}
        name={user?.displayName || ""}
        initials={(user?.displayName || "").slice(0, 1).toUpperCase()}
        open={isUserMenuOpen}
        onToggle={() => setIsUserMenuOpen(!isUserMenuOpen)}
      />
    ) : null;

  return (
    <TopBar
      showNavigationToggle={!!user}
      userMenu={userMenuMarkup}
      onNavigationToggle={onNavigationToggle}
    />
  );
}
