import {
  BlockStack,
  Card,
  IndexTable,
  Link,
  Page,
  Text,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { useRentsPayments } from "./hooks/useRentsPayments";
import { RentPayment } from "../../../types/common.types";
import { formatDate, formatMoney, dateRanges } from "../../../helpers/helpers";
import DateRangeSelectorPopup, {
  DateRange,
} from "../../../componenets/DateRangeSelectorPopup";

export default function RevenuePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [activeDateRange, setActiveDateRange] = useState<DateRange>(
    dateRanges[3],
  );

  const columns = [t("date"), t("taken_by"), t("amount")];

  const { minPage, maxPage, page, isFetching, setPage, payments, totalAmount } =
    useRentsPayments(
      15,
      activeDateRange.period.since,
      activeDateRange.period.until,
    );

  const rowMarkup = payments.map((payment: RentPayment, index) => (
    <IndexTable.Row id={payment.id as string} key={payment.id} position={index}>
      <IndexTable.Cell>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          dataPrimaryLink
          onClick={() => navigate(`/admin/rents/${payment.rent_id}`)}
        >
          <Text fontWeight="bold" as="span">
            {formatDate(payment.date, true)}
          </Text>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>{payment.taken_by_agent?.name}</IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(payment.amount)}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page fullWidth title={t("revenue")}>
      <BlockStack gap="500">
        <DateRangeSelectorPopup
          initialSelectedRangeIndex={3}
          ranges={dateRanges}
          onApply={(activeDateRange: DateRange) => {
            setActiveDateRange(activeDateRange);
          }}
        />

        <Card padding="0">
          <IndexTable
            resourceName={{
              singular: t("revenue"),
              plural: t("revenue"),
            }}
            headings={
              columns.map(
                (column): IndexTableHeading => ({ title: column }),
              ) as NonEmptyArray<IndexTableHeading>
            }
            itemCount={payments.length}
            loading={isFetching}
            selectable={false}
            pagination={{
              hasPrevious: page > minPage,
              hasNext: maxPage > 1 && page < maxPage,
              onPrevious: () => setPage(page - 1),
              onNext: () => setPage(page + 1),
            }}
          >
            <IndexTable.Row rowType="subheader" position={0} id="total">
              <IndexTable.Cell colSpan={2} scope="colgroup" as="th">
                {t("total")}
              </IndexTable.Cell>
              <IndexTable.Cell>{formatMoney(totalAmount)}</IndexTable.Cell>
            </IndexTable.Row>
            {rowMarkup}
          </IndexTable>
        </Card>
      </BlockStack>
    </Page>
  );
}
