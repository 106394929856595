import {
  Page,
  Spinner,
  InlineGrid,
  BlockStack,
  Card,
  Text,
} from "@shopify/polaris";
import { PolarisVizProvider } from "@shopify/polaris-viz";
import { useTranslation } from "react-i18next";
import React from "react";
import { OverAllStats } from "../../../types/common.types";
import { useOverAllStats } from "./hooks/useOverAllStats";
import AverageMonthlyCostPerCarChart from "./components/AverageMonthlyCostPerCarChart";
import { formatMoney } from "../../../helpers/helpers";

export default function OverallStatsPage() {
  const { t } = useTranslation();
  const { overAllStats, isLoading } = useOverAllStats();

  if (isLoading)
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spinner />
      </div>
    );

  return (
    <PolarisVizProvider>
      <Page fullWidth title={t("analytics")}>
        <BlockStack gap="200">
          <InlineGrid columns={{ sm: 2, lg: 3 }} gap="100">
            <Card>
              <Text variant="bodyMd" as="span">
                {t("total_number_of_clients")}
              </Text>
              <Text variant="heading2xl" fontWeight="semibold" as="h3">
                {overAllStats?.total_number_of_customers || 0}
              </Text>
            </Card>
            <Card>
              <Text variant="bodyMd" as="span">
                {t("total_unpaid_amount")}
              </Text>
              <Text variant="heading2xl" fontWeight="semibold" as="h3">
                {formatMoney(overAllStats?.total_unpaid_amount || 0)}
              </Text>
            </Card>
            <Card>
              <Text variant="bodyMd" as="span">
                {t("cash_register")}
              </Text>
              <Text variant="heading2xl" fontWeight="semibold" as="h3">
                {formatMoney(overAllStats?.cash_register || 0)}
              </Text>
            </Card>
          </InlineGrid>
          <InlineGrid columns={{ sm: 1, md: 2, xl: 3 }} gap="200">
            <AverageMonthlyCostPerCarChart
              stats={overAllStats as OverAllStats}
              isLoading={isLoading}
            />
          </InlineGrid>
        </BlockStack>
      </Page>
    </PolarisVizProvider>
  );
}
