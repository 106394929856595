import {
  BlockStack,
  Box,
  Button,
  Card,
  DataTable,
  InlineStack,
  Text,
} from "@shopify/polaris";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddInvoiceRentModal from "./AddInvoiceRentModal";
import { Rent } from "../../../../../types/common.types";
import { formatMoney } from "../../../../../helpers/helpers";

interface Props {
  invoiceRents: Rent[];
  setInvoiceRents: Dispatch<SetStateAction<Rent[]>>;
  customerId: string | null;
  vat: number;
  allowAdd?: boolean;
}

export default function InvoiceRents({
  invoiceRents,
  setInvoiceRents,
  customerId,
  vat,
  allowAdd = true,
}: Props) {
  const { t } = useTranslation();
  const [addInvoiceRentModalActive, setAddInvoiceRentModalActive] =
    useState(false);

  const invoiceRentsTotal = invoiceRents.reduce(
    (previousValue, rent: Rent) => previousValue + (rent.total || 0),
    0,
  );

  useEffect(() => {
    setInvoiceRents([]);
  }, [customerId]);

  return (
    <Card padding="0">
      <BlockStack gap="200">
        <Box
          paddingInlineStart="300"
          paddingInlineEnd="300"
          paddingBlockStart="200"
        >
          <InlineStack align="space-between">
            <Text as="span" fontWeight="bold">
              {t("rents")}
            </Text>
            {allowAdd && (
              <Button
                variant="plain"
                onClick={() => setAddInvoiceRentModalActive(true)}
                disabled={!customerId}
              >
                {t("add")}
              </Button>
            )}
          </InlineStack>
        </Box>
        <DataTable
          columnContentTypes={[
            "text",
            "numeric",
            "numeric",
            "numeric",
            "numeric",
            "numeric",
          ]}
          headings={[
            t("car"),
            t("price_per_day_ht"),
            t("price_per_day_ttc"),
            t("number_of_days"),
            t("total_ht"),
            t("total_ttc"),
          ]}
          rows={invoiceRents.map((rent: Rent) => {
            const pricePerDay = rent?.price_per_day || 0;
            const pricePerDayHT = pricePerDay / (1 + vat / 100);

            const total = rent?.total || 0;
            const totalHT = total / (1 + vat / 100);

            return [
              `${rent.car?.name} (${rent?.car?.license_plate})`,
              formatMoney(pricePerDayHT),
              formatMoney(pricePerDay),
              rent.number_of_days,
              formatMoney(totalHT),
              formatMoney(rent.total as number),
            ];
          })}
          showTotalsInFooter
          totals={[
            "",
            "",
            "",
            "",
            formatMoney(invoiceRentsTotal / (1 + vat / 100)),
            formatMoney(invoiceRentsTotal),
          ]}
        />
      </BlockStack>
      {allowAdd && (
        <AddInvoiceRentModal
          active={addInvoiceRentModalActive}
          setActive={setAddInvoiceRentModalActive}
          customerId={customerId}
          invoiceRents={invoiceRents}
          setInvoiceRents={setInvoiceRents}
        />
      )}
    </Card>
  );
}
