import {
  Layout,
  Page,
  Text,
  Card,
  BlockStack,
  SkeletonPage,
  SkeletonBodyText,
  TextContainer,
  SkeletonDisplayText,
  Link,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useState } from "react";
import { useInvoice } from "./hooks/useInvoice";
import { formatDate } from "../../../helpers/helpers";
import InvoiceRents from "./components/InvoiceRents/InvoiceRents";
import { useInvoiceRents } from "./hooks/useInvoiceRents";
import { usePrintInvoice } from "./hooks/usePrintInvoice";
import DeleteConfirmationDialog from "../../../componenets/DeleteConfirmationDialog";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { useDeleteInvoice } from "./hooks/useDeleteInvoice";

export default function InvoicePage() {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const navigate = useNavigate();
  const { invoiceId } = useParams();
  const { t } = useTranslation();

  const [invoicePdfLoading, setInvoicePdfLoading] = useState(false);
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
    useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const { isError, isLoading, invoice } = useInvoice(invoiceId as string);
  const { isLoading: isLoadingInvoiceRents, rents } = useInvoiceRents(
    invoiceId as string,
  );

  const printInvoice = usePrintInvoice(invoiceId as string);
  const deleteInvoice = useDeleteInvoice(invoiceId as string);

  const onHandlePrintInvoice = async () => {
    setInvoicePdfLoading(true);

    const res = await printInvoice();

    const fileType = res.headers["content-type"];
    const blob = new Blob([res.data], {
      type: fileType,
    });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
    window.URL.revokeObjectURL(url);

    setInvoicePdfLoading(false);
  };

  const onHandleDelete = async () => {
    setDeleteLoading(true);

    try {
      await deleteInvoice();

      setDeleteConfirmationDialogOpen(false);

      navigate("/admin/invoices");

      await queryClient.invalidateQueries([queryKeysConstants.invoices]);
    } catch (e: any) {
      onShowToast("Something went wrong", true);

      console.error(e.response.data);
    }

    setDeleteLoading(false);
    setDeleteConfirmationDialogOpen(false);
  };

  if (isError) {
    navigate("/admin/rents");
  }

  if (isLoading || isLoadingInvoiceRents)
    return (
      <SkeletonPage fullWidth>
        <Layout>
          <Layout.Section>
            <Card>
              <SkeletonBodyText />
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    );

  return (
    <Page
      title={invoice?.formatted_id}
      fullWidth
      backAction={{
        url: "/admin/invoices",
      }}
      primaryAction={{
        content: t("print"),
        loading: invoicePdfLoading,
        onAction: onHandlePrintInvoice,
      }}
      secondaryActions={[
        {
          content: t("delete"),
          destructive: true,
          onAction: () => setDeleteConfirmationDialogOpen(true),
        },
      ]}
    >
      <Layout>
        <Layout.Section variant="oneHalf">
          <Card>
            <BlockStack gap="400">
              <Text variant="headingMd" as="h2">
                {t("invoice_details")}
              </Text>
              <BlockStack>
                <Text as="span" fontWeight="bold">
                  {t("number")}
                </Text>
                <Text variant="bodyMd" as="span" tone="subdued">
                  {invoice?.formatted_id}
                </Text>
              </BlockStack>
              <BlockStack>
                <Text as="span" fontWeight="bold">
                  {t("date")}
                </Text>
                <Text variant="bodyMd" as="span" tone="subdued">
                  {formatDate(invoice?.invoice_date, true)}
                </Text>
              </BlockStack>
              <BlockStack>
                <Text as="span" fontWeight="bold">
                  {t("vat")}
                </Text>
                <Text variant="bodyMd" as="span" tone="subdued">
                  {`${invoice?.vat}%`}
                </Text>
              </BlockStack>
              <BlockStack>
                <Text as="span" fontWeight="bold">
                  {t("payment_type")}
                </Text>
                <Text variant="bodyMd" as="span" tone="subdued">
                  {t(invoice?.payment_type.toLowerCase() || "check")}
                </Text>
              </BlockStack>
            </BlockStack>
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <Card>
            <BlockStack gap="400">
              <Text variant="headingMd" as="h2">
                {t("customer_details")}
              </Text>
              <BlockStack>
                <Text as="span" fontWeight="bold">
                  {t("customer")}
                </Text>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link url={`/admin/customers/${invoice?.customer_id}`}>
                  {invoice?.customer?.name}
                </Link>
              </BlockStack>
            </BlockStack>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <InvoiceRents
            invoiceRents={rents}
            setInvoiceRents={() => {}}
            customerId={invoice?.customer_id as string}
            vat={invoice?.vat as number}
            allowAdd={false}
          />
        </Layout.Section>
      </Layout>
      <DeleteConfirmationDialog
        open={deleteConfirmationDialogOpen}
        loading={deleteLoading}
        onClose={() => setDeleteConfirmationDialogOpen(false)}
        onDelete={onHandleDelete}
      />
    </Page>
  );
}
