import {
  Card,
  IndexFilters,
  IndexTable,
  Link,
  Page,
  TabProps,
  Text,
  useSetIndexFiltersMode,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useInfractions } from "./hooks/useInfractions";
import { Infraction } from "../../../types/common.types";
import { formatDate } from "../../../helpers/helpers";

export default function InfractionsPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mode, setMode } = useSetIndexFiltersMode();
  const [selected, setSelected] = useState(0);
  const [status, setStatus] = useState("NON_DECLARED");

  const { minPage, maxPage, isFetching, page, setPage, infractions } =
    useInfractions(status, 10);

  const tabs: TabProps[] = [
    {
      id: "NON_DECLARED",
      content: t("non_declared"),
      isLocked: true,
    },
    {
      id: "DECLARED",
      content: t("declared"),
    },
    {
      id: "APPROVED",
      content: t("approved"),
    },
  ];

  const columns = [
    { label: t("pv_number") },
    { label: t("car_license_plate") },
    { label: t("date") },
    { label: t("status") },
  ];

  const handleTabChange = useCallback((value: number) => {
    setSelected(value);
    setStatus(tabs[value].id);
  }, []);

  const rowMarkup = infractions.map((infraction: Infraction, index) => (
    <IndexTable.Row
      id={infraction.id as string}
      key={infraction.id}
      position={index}
    >
      <IndexTable.Cell>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          dataPrimaryLink
          onClick={() => navigate(`/admin/infractions/${infraction.id}`)}
        >
          <Text fontWeight="bold" as="span">
            {`${infraction.id}`}
          </Text>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>{infraction.license_plate}</IndexTable.Cell>
      <IndexTable.Cell>
        {formatDate(infraction.date, false, true)}
      </IndexTable.Cell>
      <IndexTable.Cell>{t(infraction.status.toLowerCase())}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page fullWidth title={t("infractions")}>
      <Card padding="0">
        <IndexFilters
          queryValue=""
          queryPlaceholder={t("searching")}
          onQueryChange={() => {}}
          onQueryClear={() => {}}
          cancelAction={{
            onAction: () => {},
            disabled: false,
            loading: false,
          }}
          tabs={tabs}
          selected={selected}
          onSelect={handleTabChange}
          filters={[]}
          onClearAll={() => {}}
          mode={mode}
          setMode={setMode}
          canCreateNewView={false}
        />
        <IndexTable
          resourceName={{
            singular: t("infraction"),
            plural: t("infractions"),
          }}
          itemCount={infractions.length}
          loading={isFetching}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          selectable={false}
          pagination={{
            hasPrevious: page > minPage,
            hasNext: maxPage > 1 && page < maxPage,
            onPrevious: () => setPage(page - 1),
            onNext: () => setPage(page + 1),
          }}
        >
          {rowMarkup}
        </IndexTable>
      </Card>
    </Page>
  );
}
