import { Modal, FormLayout } from "@shopify/polaris";
import React, { useContext, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import TextFieldInput from "../../../componenets/TextFieldInput";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { CashRegisterFunds } from "../../../types/common.types";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import { useAddCashRegisterFund } from "./hooks/useAddCashRegisterFund";
import DatePickerInput from "../../../componenets/DatePickerInput";

interface CarModalProps {
  active: boolean;
  setActive: (status: boolean) => void;
}

const schema = yup
  .object({
    label: yup.string().required(),
    amount: yup.number().min(1).required(),
    date: yup.date().required(),
  })
  .required();

export default function NewCashRegisterFundModal({
  active,
  setActive,
}: CarModalProps) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, reset } = useForm<CashRegisterFunds>({
    defaultValues: {
      label: "",
      amount: 0,
      date: new Date(),
    },
    resolver: yupResolver(schema),
  });

  const addFund = useAddCashRegisterFund();

  const handleClose = () => {
    reset({
      label: "",
      amount: 1,
      date: new Date(),
    });

    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (fund: CashRegisterFunds) => {
    setLoading(true);

    try {
      await addFund(fund);

      await queryClient.invalidateQueries([queryKeysConstants.cashRegister]);

      handleClose();

      onShowToast("Fund Saved", false);
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title={t("new_fund")}
      primaryAction={{
        content: t("save"),
        disabled: loading,
        loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
      secondaryActions={[
        {
          content: t("cancel"),
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextFieldInput
            control={control}
            label={t("label")}
            name="label"
            type="text"
          />
          <TextFieldInput
            control={control}
            label={t("amount")}
            name="amount"
            type="number"
          />
          <DatePickerInput control={control} name="date" label={t("date")} />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
