import {
  BlockStack,
  Card,
  Text,
  IndexTable,
  InlineStack,
  Button,
  Box,
  Link,
} from "@shopify/polaris";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCarDocuments } from "./hooks/useCarDocuments";
import { CarDocument } from "../../../../../types/common.types";
import { formatDate } from "../../../../../helpers/helpers";
import CarDocumentModal from "./CarDocumentModal";

export default function CarDocuments({ carId }: { carId: string }) {
  const { t } = useTranslation();

  const columns = [{ label: t("name") }, { label: t("added_at") }];

  const [CarDocumentModalActive, setCarDocumentModalActive] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<CarDocument | null>(
    null,
  );

  const { carDocuments, isFetching } = useCarDocuments(carId, 10);

  const rowMarkup = carDocuments.map(
    (carDocument: CarDocument, index: number) => (
      <IndexTable.Row
        id={carDocument.id as string}
        key={carDocument.id}
        position={index}
        onClick={() => {
          setSelectedDocument(carDocument);
          setCarDocumentModalActive(true);
        }}
      >
        <IndexTable.Cell>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link target="_blank" url={carDocument.file_public_url as string}>
            {t(carDocument.type.toLowerCase())}
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {formatDate(carDocument.added_at, true, true)}
        </IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  return (
    <Card padding="0">
      <BlockStack gap="200">
        <Box
          paddingInlineStart="300"
          paddingInlineEnd="300"
          paddingBlockStart="200"
        >
          <InlineStack align="space-between">
            <Text as="span" fontWeight="bold">
              {t("documents")}
            </Text>
            <Button
              variant="plain"
              onClick={() => {
                setSelectedDocument(null);
                setCarDocumentModalActive(true);
              }}
            >
              {t("add")}
            </Button>
          </InlineStack>
        </Box>

        <IndexTable
          resourceName={{
            singular: t("document"),
            plural: t("documents"),
          }}
          itemCount={carDocuments.length}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          loading={isFetching}
          selectable={false}
        >
          {rowMarkup}
        </IndexTable>
      </BlockStack>
      <CarDocumentModal
        document={selectedDocument}
        carId={carId}
        active={CarDocumentModalActive}
        setActive={(status: boolean) => {
          if (!status) {
            setSelectedDocument(null);
          }

          setCarDocumentModalActive(status);
        }}
      />
    </Card>
  );
}
