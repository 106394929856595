import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import { Invoice } from "../../../../types/common.types";

async function addInvoice(invoice: Invoice): Promise<Invoice> {
  const { data } = await axiosInstance.post(`/invoices/`, invoice, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });

  return data;
}

export function useAddInvoice(): UseMutateAsyncFunction<
  Invoice,
  unknown,
  Invoice,
  unknown
> {
  const { mutateAsync } = useMutation((invoice: Invoice) =>
    addInvoice(invoice),
  );

  return mutateAsync;
}
