import { useQuery } from "@tanstack/react-query";
import { Infraction } from "../../../../types/common.types";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";
import queryKeysConstants from "../../../../constants/queryKeys.constants";

async function getInfraction(infractionId: string): Promise<Infraction> {
  const { data } = await axiosInstance.get(`/infractions/${infractionId}`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return data;
}

interface useCarProps {
  isLoading: boolean;
  isError: boolean;
  infraction: Infraction | undefined;
}

export function useInfraction(infractionId: string): useCarProps {
  const { data, isLoading, isError } = useQuery(
    [queryKeysConstants.infractions, infractionId],
    () => getInfraction(infractionId),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isError,
    isLoading,
    infraction: data,
  };
}
