import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

export const app = initializeApp({
  apiKey: "AIzaSyDy-XxenNGIYep3XlYHa6OX7AVSknMfKDA",
  authDomain: "vroomventures-40cce.firebaseapp.com",
  projectId: "vroomventures-40cce",
  storageBucket: "vroomventures-40cce.appspot.com",
  messagingSenderId: "201084938214",
  appId: "1:201084938214:web:c627dfcea7aa6fac6c1827",
  measurementId: "G-WCCN13JTGF",
});
export const auth = getAuth(app);
