import { IndexTable, Modal, useIndexResourceState } from "@shopify/polaris";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { useUserRents } from "./hooks/useUserRents";
import { Rent } from "../../../../../types/common.types";
import { formatDate, formatMoney } from "../../../../../helpers/helpers";

interface Props {
  active: boolean;
  setActive: (status: boolean) => void;
  customerId: string | null;
  invoiceRents: Rent[];
  setInvoiceRents: React.Dispatch<React.SetStateAction<Rent[]>>;
}

export default function AddInvoiceRentModal({
  active,
  setActive,
  customerId,
  invoiceRents,
  setInvoiceRents,
}: Props) {
  // const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { isFetching, rents } = useUserRents(customerId);
  const selectedRentsIds = invoiceRents.map((rent: Rent) => rent.id);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState([]);

  const columns: NonEmptyArray<IndexTableHeading> = [
    { title: t("car") },
    { title: t("price_per_day") },
    { title: t("number_of_days") },
    { title: t("total") },
    { title: t("amount_paid") },
    { title: t("amount_left") },
    { title: t("from_date") },
    { title: t("to_date") },
  ];

  const handleClose = () => {
    setActive(false);
    setLoading(false);

    clearSelection();
  };

  const onHandleSubmit = () => {
    const selectedRents = rents.filter((rent: Rent) =>
      selectedResources.includes(rent.id as string),
    );

    setInvoiceRents((prevState: Rent[]) => [...prevState, ...selectedRents]);

    handleClose();
  };

  const filteredRents = rents.filter(
    (rent: Rent) => !selectedRentsIds.includes(rent.id),
  );

  const rowMarkup = filteredRents.map((rent: Rent, index) => (
    <IndexTable.Row
      id={rent.id as string}
      key={rent.id}
      position={index}
      selected={selectedResources.includes(rent.id as string)}
    >
      <IndexTable.Cell>
        {`${rent.car?.name} (${rent?.car?.license_plate})`}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {formatMoney(rent.price_per_day as number)}
      </IndexTable.Cell>
      <IndexTable.Cell>{rent.number_of_days}</IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(rent.total as number)}</IndexTable.Cell>
      <IndexTable.Cell>
        {formatMoney(rent.amount_paid as number)}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {formatMoney(rent.amount_left as number)}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {formatDate(rent.from_date, false, true)}
      </IndexTable.Cell>
      <IndexTable.Cell>{formatDate(rent.to_date, false, true)}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title={t("add_rent")}
      loading={isFetching}
      primaryAction={{
        content: t("add"),
        disabled: loading || !filteredRents.length,
        loading,
        onAction: onHandleSubmit,
      }}
      secondaryActions={[
        {
          content: t("cancel"),
          onAction: handleClose,
        },
      ]}
    >
      <IndexTable
        resourceName={{ singular: t("rent"), plural: t("rents") }}
        headings={columns}
        itemCount={filteredRents.length}
        selectedItemsCount={
          allResourcesSelected ? "All" : selectedResources.length
        }
        onSelectionChange={handleSelectionChange}
      >
        {rowMarkup}
      </IndexTable>
    </Modal>
  );
}
